$primary: #599ca4;

$secondary: #606060;

$dark: #333636;

$light: #f5f5f5;

$font-primary: "Inter", sans-serif;
$font-secondary: "Lora", serif;

$mobile-res: 1200px;
