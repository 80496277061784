@use "./abstracts/" as *;

* {
  font-family: $font-primary;
  box-sizing: border-box;
  cursor: default;
  letter-spacing: -0.02em !important;
}

html {
  scroll-behavior: smooth;
  overflow-y: overlay;
}

body {
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
}

// body.modal-open {
//   height: 100vh;
//   overflow-y: hidden;
// }

a {
  text-decoration: none;
  cursor: pointer;
}

object,
svg {
  pointer-events: none;
  cursor: pointer;
}

::selection {
  background-color: rgba($primary, 0.3);
}

.fontPrimary {
  font-family: $font-primary;
}

.fontSecondary {
  font-family: $font-secondary;
}

.btn {
  padding: 12px 24px;
  border: none;
  border-radius: 14px;
  font-weight: 500;
  font-size: 18px;
  color: $dark;
  @include transition;

  &:hover {
    box-shadow: 0px 4px 40px -3px rgba($primary, 0.5);
    transform: translateY(-2px);
  }

  &.btn-primary {
    background-color: $primary;

    &:hover {
      color: $light;

      svg {
        fill: $light;
      }
    }
  }

  &.btn-tertiary {
    background-color: transparent;
    border: 2px solid $primary;
    color: $primary;

    &:hover {
      color: $secondary;
      border-color: $secondary;
    }
  }

  &.btn-plus {
    padding-left: 0 !important;
    box-shadow: none !important;
    opacity: 0.75;

    svg {
      fill: $primary;
      width: 32px;
    }

    &:hover {
      opacity: 1;
    }

    @media screen and (max-width: $mobile-res) {
      svg {
        width: 24px;
      }
    }
  }

  &.btn-edit {
    display: none;
    position: absolute;
    top: 0 !important;
    right: 0;
    left: auto;
    width: fit-content !important;
    height: fit-content !important;
    padding: 0px 6px 4px 8px !important;
    background-color: $primary !important;
    border-radius: 4px;
    box-shadow: none !important;
    transform: none !important;

    svg {
      fill: $light !important;
      stroke: none !important;
      width: 18px !important;
    }
  }

  svg {
    width: 20px;
    cursor: pointer;
    @include transition;
  }
}

.admin-hover-box {
  outline: 1px dashed transparent;
  outline-offset: 3px;
  border-radius: 4px;

  &:hover {
    outline-color: $primary;

    .btn-edit {
      display: block;
    }
  }

  @media screen and (max-width: $mobile-res) {
    outline-color: $primary;

    .btn-edit {
      display: block;
    }
  }
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.form-label {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 4px;
}

.form-control {
  padding: 12px 16px;
  box-shadow: none !important;
  border: 2px solid rgba($primary, 0.25);
  cursor: text;

  &:focus {
    border-color: $primary;
    box-shadow: 0px 6px 20px -9px rgba($primary, 0.25) !important;
  }
}

.form-group {
  label {
    font-size: 15px;
    font-weight: 600;
    color: rgba($dark, 0.8);
    margin-bottom: 5px;
  }
}

.navbar {
  padding: 20px 0;
  background-image: linear-gradient(
    to right,
    rgba($secondary, 0.3),
    rgba($primary, 0.3)
  );
  backdrop-filter: blur(25px);
  opacity: 1;
  transition: all 0.25s ease 0s !important;

  @media screen and (max-width: 1200px) {
    padding: 20px;
    background-image: linear-gradient(
      to left,
      rgba($primary, 0.2),
      rgba($light, 0.75)
    );

    .navbar-nav {
      margin: 20px 0;
    }
  }

  .navbar-brand {
    img {
      cursor: pointer;
    }
  }

  .navbar-nav {
    .nav-item {
      font-weight: 600;
      font-size: 16px;
    }

    .nav-link {
      position: relative;
      cursor: pointer;
      @include transition;
      color: $light;

      &.link-dark {
        color: $dark !important;
        margin: 0;
      }

      @media screen and (max-width: 1200px) {
        color: $dark;
      }

      &:after {
        content: "•";
        opacity: 0;
        color: $primary;
        position: absolute;
        bottom: -3px;
        left: 0;
        right: 0;
        text-align: center;
        @include transition;
      }

      &:hover {
        color: $primary;
        transform: translateY(-2px);

        &:after {
          opacity: 1;
          bottom: -8px;
        }
      }
    }
  }

  .btn {
    font-weight: 600;

    svg {
      margin-right: 6px;
      fill: $dark;
    }

    &.btn-admin {
      background-color: transparent;
      border: 2px solid $dark;
      border-radius: 40px;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      color: $dark;

      svg {
        margin: 0;
        fill: $dark;
      }

      @media screen and (max-width: $mobile-res) {
        width: 30px;
        height: 30px;
        padding: 7px;
      }
    }
  }

  .btn-toggle {
    padding: 4px 8px;
    color: $secondary;
    background-color: transparent !important;
    border: none;
    box-shadow: none;

    svg {
      cursor: pointer;
    }
  }
}

.dropdown {
  .dropdown-menu {
    padding: 0 !important;
    border-radius: 12px;
    margin-top: 15px;
    background-image: linear-gradient(
      120deg,
      rgba($secondary, 0.1) 5%,
      rgba($primary, 0.4) 95%
    );
    backdrop-filter: blur(50px);
    border: 1px solid rgba($dark, 0.025);
    box-shadow: 0px 2px 60px -3px rgba($primary, 0.2);

    .dropdown-item {
      padding: 12px 24px;
      border-radius: 12px 12px 0 0;
      font-weight: 500;
      @include transition;

      &:hover {
        background-color: rgba($primary, 0.25);
      }
    }

    li {
      &:first-child {
        .dropdown-item {
          border-radius: 12px 12px 0 0;
        }
      }

      &:last-child {
        .dropdown-item {
          border-radius: 0 0 12px 12px;
        }
        border-top: 1px solid rgba($primary, 0.5);
      }
    }
  }
}

.title-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  h1 {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 72px;
    font-weight: 600;
    text-align: center;
    color: $light;
    text-shadow: 0px 6px 18px rgba($primary, 0.75);
  }

  @media screen and (max-width: $mobile-res) {
    height: 260px;

    h1 {
      font-size: 42px;
      top: 35%;
      bottom: 0;
    }
  }
}

.cta {
  position: relative;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 450px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($dark, 0.15);
  }

  .container {
    z-index: 1;
  }

  p {
    font-size: 22px;
    font-family: $font-secondary;
    line-height: 1.75;
    font-weight: 500;
    color: $light;
    text-shadow: 0px 1px 20px rgba($dark, 0.75);
  }

  .btn {
    padding: 20px;
    font-size: 22px;
    width: 200px;
    box-shadow: 0px 4px 40px -3px rgba($secondary, 0.5) !important;
  }

  @media screen and (max-width: $mobile-res) {
    min-height: 0vh !important;
    padding: 20px;

    h1 {
      font-size: 28px;
      line-height: 1.1;
    }

    p {
      font-size: 18px !important;
      line-height: 1.6 !important;
      font-weight: 500 !important;
    }

    .btn {
      display: block;
      margin: auto;
      font-size: 18px;
      width: 200px;
    }
  }
}

#homePage {
  #landingSection {
    position: relative;
    min-height: 100vh;
    background-image: url(../assets/images/home_img.jpg);
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($dark, 0.3);
    }

    .container {
      z-index: 1;
    }

    h2 {
      font-size: 90px;
      font-weight: 600;
      line-height: 1;
      color: $light;
    }

    h5 {
      font-family: $font-secondary;
      font-size: 24px;
      font-style: italic;
      color: $light;
      opacity: 0.85;
      line-height: 1.75;
      margin: 20px 0;
    }

    .btn {
      padding: 20px 28px;
      font-size: 18px;
      font-weight: 600;
      box-shadow: 0px 4px 40px -3px rgba($secondary, 0.5);
    }

    @media screen and (max-width: $mobile-res) {
      min-height: 100vh;
      background-position: center;

      h2 {
        font-size: 60px;
        line-height: 1;
      }

      h5 {
        font-family: $font-secondary;
        font-size: 22px;
        opacity: 0.85;
        line-height: 1.5;
        margin: 20px 0;
      }
    }
  }

  .homeContent {
    h1,
    h2 {
      font-size: 60px;
      font-weight: 700;
      text-align: center;
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    p {
      font-size: 20px;
      font-family: $font-secondary;
      line-height: 1.75;
      font-weight: 500;
    }

    .tab1,
    .tab2,
    .tab3 {
      .img-box {
        position: relative;
        height: 500px;
        border-radius: 12px;
        overflow: hidden;
        padding: 0;
        box-shadow: 0px 4px 80px -10px rgba($primary, 0.5);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
    }

    .tab1 {
      .img-box {
        img {
          height: auto;
        }

        .text {
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 30px;
          margin: 30px;
          color: $light;
          background-image: linear-gradient(
            to right,
            rgba($primary, 0.4),
            rgba($secondary, 0.5)
          );
          box-shadow: 0px 4px 80px -10px rgba($secondary, 0.5);
          border-radius: 9px;
          z-index: 1;

          h2 {
            font-size: 28px;
            font-weight: 600;
          }

          h4 {
            font-size: 18px;
            font-family: $font-secondary;
            font-weight: 600;
            margin-bottom: 20px;
            line-height: 1.4;
          }
        }
      }
    }

    @media screen and (max-width: $mobile-res) {
      min-height: 50vh !important;

      .tab1,
      .tab2,
      .tab3 {
        .img-box {
          width: 98%;
        }
      }

      h1,
      h2 {
        font-size: 28px;
        line-height: 1.1;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .cta {
    background-image: url(../assets/images/home_img5.jpg);

    .btn {
      padding: 12px 18px;
      background-color: $light;
      color: $primary;
      width: fit-content;
    }
  }
}

#aboutPage {
  margin-top: 11vh;

  .title-box {
    background-image: url(../assets/images/about_img2.jpg);

    h1 {
      top: 30%;
    }
  }

  .cta {
    background-image: url(../assets/images/about_img3.jpg);
  }

  p {
    font-size: 20px;
    font-family: $font-secondary;
    line-height: 1.75;
    font-weight: 500;
  }

  .img-box {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    height: 600px;
    min-width: 400px;
    padding: 0;
    float: left;
    box-shadow: 45px 30px 80px -20px rgba($primary, 0.25);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($dark, 0.1);
    }

    .btn {
      position: absolute;
      left: 7.5%;
      right: 0;
      bottom: 5%;
      z-index: 1;
      width: 85%;
      background-color: rgba($dark, 0.5);
      box-shadow: 0px 4px 40px -3px rgba($dark, 0.75) !important;

      &.btn-tertiary {
        border-color: $light;
        color: $light;
        display: flex;
        gap: 9px;
        justify-content: center;
        align-items: center;

        svg {
          width: 28px;
        }

        &:hover {
          box-shadow: 0px 4px 40px -3px rgba($light, 0.5) !important;
        }
      }
    }
  }

  #ptsModal {
    .close-btn {
      position: relative;
      display: flex;
      background-color: transparent;
      border-radius: 50px;
      font-size: 14px;
      padding: 10px;
      border: 1px solid $secondary;
      color: $secondary;
      box-shadow: none !important;
    }

    .btn-secondary {
      background-color: transparent;
      border: 1px solid $dark;
      color: $dark !important;
      box-shadow: none !important;
    }

    .btn-pts {
      border: 1px solid $primary;
      background-color: rgba($primary, 0.1);
      color: $primary;
      box-shadow: none !important;

      img {
        cursor: pointer;
      }
    }

    img {
      width: 200px;
      display: flex;
      justify-content: center;
      margin: 0 auto 0 auto;
    }

    @media screen and (max-width: $mobile-res) {
      .close-btn {
        font-size: 13px;
        padding: 6px;
      }

      img {
        width: 175px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .btn {
    padding: 12px 18px;
    font-size: 20px;
    width: fit-content;
    box-shadow: 0px 4px 40px -3px rgba($primary, 0.75) !important;

    &.btn-primary {
      background-color: $light;
      color: $primary;
    }

    &.btn-tertiary {
      background-color: transparent;
      color: $light;
      border-color: $light;
    }
  }

  @media screen and (max-width: $mobile-res) {
    .text-box {
      padding: 20px 30px;

      p {
        font-size: 18px;
      }
    }

    .img-box {
      height: 500px;
      float: none;
      min-width: auto;
    }

    .title-box {
      h1 {
        top: 40%;
        bottom: 0;
      }
    }
  }
}

#contactPage {
  margin-top: 11vh;

  a {
    text-decoration: none;
    cursor: pointer;
    color: rgba($primary, 0.75);
    border-bottom: 1px solid rgba($primary, 0.3);
    @include transition;

    &:hover {
      color: $primary;
      border-bottom: 1px solid $primary;
    }
  }

  .title-box {
    background-image: url(../assets/images/contact_img.jpg);
  }

  p {
    font-size: 20px;
    font-family: $font-secondary;
    line-height: 1.75;
    font-weight: 500;

    span {
      font-family: $font-secondary !important;
      font-weight: 700;
    }
  }

  .alert {
    padding: 8px 16px;
    width: fit-content;
    margin: 20px auto;
    font-weight: 500;
    position: fixed;
    left: 60px;
    bottom: 30px;
    max-width: 400px;
    z-index: 1;
  }

  @media screen and (max-width: $mobile-res) {
    margin-top: 5vh;

    .container {
      padding: 0 4vw;
    }

    .title-box {
      h1 {
        top: 45%;
        bottom: 0;
      }
    }

    .text-box {
      p {
        font-size: 18px;
      }
    }
  }
}

#faqPage {
  margin-top: 11vh;

  a {
    text-decoration: none;
    cursor: pointer;
    color: rgba($primary, 0.75);
    border-bottom: 1px solid rgba($primary, 0.3);
    @include transition;

    &:hover {
      color: $primary;
      border-bottom: 1px solid $primary;
    }
  }

  .title-box {
    background-image: url(../assets/images/faq_img1.jpg);

    .btn {
      &.btn-plus {
        svg {
          stroke: $light;
          width: 60px;
        }
      }
    }
  }

  .cta {
    background-image: url(../assets/images/faq_img2.jpg);
  }

  .accordion {
    .accordion-item {
      .accordion-button[aria-expanded="true"] {
        border-bottom: 1px solid $primary !important;
        color: $primary;

        &::after {
          background-image: url(../assets/icons/chevron-down-colored.svg);
        }
      }

      .accordion-button {
        position: relative;
        text-align: left;
        width: 100%;
        padding: 32px 0;
        color: $dark;
        font-size: 22px;
        font-weight: 600;
        box-shadow: none;
        background-color: transparent;
        cursor: pointer;

        &:hover {
          color: $primary;
        }

        &::after {
          background-image: url(../assets/icons/chevron-down.svg);
          background-size: 120%;
        }
      }
    }

    .accordion-body p {
      overflow: hidden;
      font-family: $font-secondary !important;
      font-size: 20px;
      font-weight: 500;
      color: $secondary;
      line-height: 1.75;
      margin: 12px 0;

      span {
        font-family: $font-secondary;
        font-weight: 600;
      }
    }
  }

  .btn-tertiary {
    padding: 12px 18px;
    width: fit-content;
    border-color: $light;
    color: $light;

    &:hover {
      box-shadow: 0px 4px 40px -3px rgba($light, 0.5) !important;
    }
  }

  @media screen and (max-width: $mobile-res) {
    .accordion {
      padding: 0 20px;

      .accordion-item {
        .accordion-button {
          padding: 24px 16px;
          font-size: 18px;

          &::after {
            background-size: 100%;
          }
        }
      }

      .accordion-body {
        font-size: 16px;
        line-height: 1.5;
        margin: 6px 0;
      }
    }
  }
}

#whatToExpectPage {
  margin-top: 11vh;

  .title-box {
    background-image: url(../assets/images/wte_img1.jpg);
  }

  .cta {
    background-image: url(../assets/images/wte_img2.jpg);
  }

  p {
    font-size: 20px;
    font-family: $font-secondary;
    line-height: 1.75;
    font-weight: 500;
  }

  h2 {
    font-family: $font-primary;
    font-weight: 700;
    font-size: 28px;
    color: $primary;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: rgba($primary, 0.75);
    border-bottom: 1px solid rgba($primary, 0.3);
    @include transition;

    &:hover {
      color: $primary;
      border-bottom: 1px solid $primary;
    }
  }

  .img-box {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    height: 600px;
    padding: 0;
    float: left;
    box-shadow: 45px 30px 80px -20px rgba($primary, 0.25);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($dark, 0.1);
    }
  }

  .btn {
    padding: 12px 18px;
    font-size: 20px;
    width: fit-content;
    box-shadow: 0px 4px 40px -3px rgba($primary, 0.75) !important;

    &.btn-primary {
      background-color: $light;
      color: $primary;
    }

    &.btn-tertiary {
      background-color: transparent;
      color: $light;
      border-color: $light;
    }
  }

  @media screen and (max-width: $mobile-res) {
    .text-box {
      padding: 20px 30px;

      p {
        font-size: 18px;
      }
    }

    .img-box {
      height: 500px;
      float: none;
    }

    h2 {
      font-size: 24px;
    }

    .title-box {
      h1 {
        font-size: 36px;
        top: 32%;
        bottom: 0;
      }
    }

    .btn {
      padding: 12px 18px;
      font-size: 18px;
    }
  }
}

#specialtiesPage {
  margin-top: 11vh;

  .title-box {
    background-image: url(../assets/images/specialties_img1.jpg);
  }

  .cta {
    background-image: url(../assets/images/specialties_img2.jpg);
  }

  p,
  li {
    font-size: 20px;
    font-family: $font-secondary;
    line-height: 1.75;
    font-weight: 500;
  }

  ul {
    margin: 0;
    padding: 0 20px;

    li {
      font-style: italic;
      padding: 4px 0;
    }
  }

  h2 {
    font-family: $font-primary;
    font-weight: 700;
    font-size: 28px;
    color: $primary;
  }

  .btn {
    padding: 12px 18px;
    font-size: 20px;
    width: fit-content;
    box-shadow: 0px 4px 40px -3px rgba($primary, 0.75) !important;

    &.btn-primary {
      background-color: $light;
      color: $primary;
    }

    &.btn-tertiary {
      background-color: transparent;
      color: $light;
      border-color: $light;
    }
  }

  .img-box {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    height: 500px;
    min-width: 360px;
    padding: 0;
    box-shadow: 45px 30px 80px -20px rgba($primary, 0.25);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($dark, 0.1);
    }

    &.img-box1 {
      float: right;
    }

    &.img-box2 {
      float: left;
    }
  }

  @media screen and (max-width: $mobile-res) {
    .text-box {
      padding: 20px 30px;

      p,
      li {
        font-size: 18px;
      }
    }

    .img-box {
      width: 100%;
      min-width: auto;
      height: 450px;
      float: none;

      &.img-box1,
      &.img-box2 {
        float: none;
      }
    }

    h2 {
      font-size: 24px;
    }

    .title-box {
      h1 {
        top: 40%;
        bottom: 0;
      }
    }

    .btn {
      padding: 12px 18px;
      font-size: 18px;
    }
  }
}

#servicesPage {
  margin-top: 11vh;

  .title-box {
    background-image: url(../assets/images/services_img1.jpg);

    .btn {
      box-shadow: none !important;

      &.btn-plus {
        svg {
          stroke: $light;
          width: 60px;
        }
      }
    }
  }

  .cta {
    background-image: url(../assets/images/services_img2.jpg);
  }

  p {
    font-size: 20px;
    font-family: $font-secondary;
    line-height: 1.75;
    font-weight: 500;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: rgba($primary, 0.75);
    border-bottom: 1px solid rgba($primary, 0.3);
    @include transition;

    &:hover {
      color: $primary;
      border-bottom: 1px solid $primary;
    }
  }

  h2 {
    font-family: $font-primary;
    font-weight: 700;
    font-size: 28px;
    color: $primary;
  }

  .btn {
    padding: 12px 18px;
    font-size: 20px;
    width: fit-content;
    box-shadow: 0px 4px 40px -3px rgba($primary, 0.75) !important;

    &.btn-primary {
      background-color: $light;
      color: $primary;
    }

    &.btn-secondary {
      background-color: transparent;
      color: $primary;
      border: 2px solid $primary;
      box-shadow: none !important;
    }

    &.btn-tertiary {
      background-color: transparent;
      color: $light;
      border-color: $light;
    }
  }

  .img-box {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    height: 500px;
    padding: 0;
    box-shadow: 45px 30px 80px -20px rgba($primary, 0.25);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($dark, 0.1);
    }

    &.img-box1 {
      float: right;
    }

    &.img-box2 {
      float: left;
    }
  }

  @media screen and (max-width: $mobile-res) {
    .text-box {
      padding: 20px 30px;

      p {
        font-size: 18px;
      }
    }

    .img-box {
      width: 100%;
      height: 450px;
      float: none;

      &.img-box1,
      &.img-box2 {
        float: none;
      }
    }

    h2 {
      font-size: 24px;
    }

    .title-box {
      h1 {
        top: 40%;
        bottom: 0;
      }
    }

    .btn {
      padding: 12px 18px;
      font-size: 18px;
    }
  }
}

#articleModal,
#faqEditorModal,
#editorModal {
  .modal-title {
    color: $primary;
    font-weight: 600;
    font-size: 20px;
  }

  .form-group {
    margin-bottom: 20px;

    .form-control {
      padding: 10px 12px;
      box-shadow: none !important;
      border: 1px solid rgba($secondary, 0.25);
      cursor: text;

      &:focus {
        border-color: $primary;
      }

      &::placeholder {
        color: rgba($dark, 0.5);
      }
    }

    label {
      font-size: 15px;
      font-weight: 600;
      color: rgba($dark, 0.8);
      margin-bottom: 5px;
    }
  }

  .btn {
    padding: 8px 12px !important;
    font-size: 18px !important;
    background: none;
    border-radius: 6px;

    &.btn-primary {
      background-color: $primary !important;
      box-shadow: none !important;
      border: 1px $primary solid !important;
      color: $light !important;
    }

    &.btn-secondary {
      color: $primary;
      border: 1px $primary solid !important;
      box-shadow: none !important;
    }
  }

  .rte-editor {
    span p,
    li {
      font-size: 16px;
      font-family: $font-secondary;
    }

    p {
      line-height: 36px;
    }

    h2,
    h3 {
      font-family: $font-primary;
      font-weight: 700;
      font-size: 28px;
      color: $primary;
    }

    h3 {
      font-size: 24px;
    }

    h2,
    h3 {
      margin: 25px 0;
    }

    a {
      text-decoration: none;
      cursor: pointer;
      color: rgba($primary, 0.75);
      border-bottom: 1px solid rgba($primary, 0.3);
      @include transition;

      &:hover {
        color: $primary;
        border-bottom: 1px solid $primary;
      }
    }

    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
      margin: 10px 0;
      border-radius: 10px;
    }

    @media screen and (max-width: $mobile-res) {
      p {
        line-height: 32px;
      }

      h2 {
        font-size: 22px;
      }

      h3 {
        font-size: 20px;
      }

      img {
        height: 100%;
      }
    }
  }
}

#deleteModal,
#deleteFaqModal {
  .btn {
    &.btn-danger,
    &.btn-light {
      border-radius: 4px;
      font-size: 18px;
      padding: 6px 12px !important;
      box-shadow: none !important;
    }

    &.btn-danger {
      color: $light !important;
    }
  }
}

#editorModal,
#faqEditorModal {
  .form-group {
    margin-bottom: 0;
  }
}

#blogPage {
  margin-top: 11vh;

  .title-box {
    background-image: url(../assets/images/blog_img1.jpg);

    .btn {
      box-shadow: none !important;

      &.btn-plus {
        svg {
          stroke: $light;
          width: 60px;
        }
      }
    }
  }

  .cta {
    background-image: url(../assets/images/blog_img2.jpg);
  }

  p,
  li {
    font-size: 20px;
    font-family: $font-secondary;
    line-height: 1.75;
    font-weight: 500;
  }

  h2 {
    font-family: $font-primary;
    font-weight: 700;
    font-size: 28px;
    color: $primary;
  }

  .btn {
    padding: 12px 18px;
    font-size: 20px;
    width: fit-content;
    box-shadow: 0px 4px 40px -3px rgba($primary, 0.75) !important;

    &.btn-primary {
      background-color: $light;
      color: $primary;
    }

    &.btn-tertiary {
      background-color: transparent;
      color: $light;
      border-color: $light;
    }
  }

  .latest-article {
    position: sticky;
    top: 130px;
    background: rgba($primary, 0.025);
    border-radius: 12px;
    border: 1px solid rgba($primary, 0.21);
    font-family: $font-secondary;
    @include transition;

    .main-img-wrapper {
      width: 100%;
      max-height: 400px;
      border-radius: 12px 12px 0 0;
      overflow: hidden;
      border: 1px solid rgba($primary, 0.1);

      img {
        width: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .content {
      padding: 20px;

      h2 {
        font-weight: 700;
        color: $dark;
        font-size: 24px;
        cursor: pointer;

        @media screen and (max-width: $mobile_res) {
          font-size: 17px !important;
        }
      }

      p {
        font-size: 16px;

        strong {
          font-weight: 400;
        }
      }

      .action-btn-wrapper {
        position: relative;
        margin-left: auto;

        .edit-btn {
          position: relative;
          padding: 0px;
          box-shadow: none !important;

          svg {
            width: 18px;
          }
        }
      }

      .author-wrapper {
        .img-wrapper {
          width: 60px;
          height: 60px;
          overflow: hidden;
          border-radius: 50%;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .author-details {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 10px;

          p {
            margin: 0;
            font-family: $font-secondary;
            font-size: 14px;
            opacity: 0.7;

            &.name {
              font-weight: 700;
            }

            &.date {
              font-weight: 600;
            }
          }
        }
      }

      .action-wrapper {
        p {
          font-size: 13px;
          opacity: 0.8;
        }
      }
    }

    &:hover {
      box-shadow: 0 15px 40px -5px rgb(0 0 0 / 0.05),
        0 12px 40px -6px rgb(0 0 0 / 0.05);
    }
  }

  .latest-article-mini {
    margin-bottom: 25px;
    height: 120px;
    overflow: hidden;
    position: relative;
    top: 0;
    @include transition;

    @media screen and (max-width: $mobile_res) {
      margin-top: 25px;
    }

    .main-img-wrapper {
      max-width: 120px;
      height: 100%;
      overflow: hidden;
      border-radius: 5px;
      border: 1px solid rgba($primary, 0.2);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .content {
      h2 {
        font-size: 18px;
      }

      .action-btn-wrapper {
        position: relative;

        .edit-btn {
          position: relative;
          padding: 0px;
          box-shadow: none !important;

          svg {
            width: 18px;
          }
        }
      }

      .author-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: -10px !important;

        .img-wrapper {
          width: 30px;

          @media screen and (max-width: $mobile_res) {
            width: 40px;
          }

          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  @media screen and (max-width: $mobile-res) {
    h2 {
      font-size: 24px;
    }

    .btn {
      padding: 12px 18px;
      font-size: 18px;
    }
  }
}

#articlePage {
  margin-top: 11vh;

  .cta {
    background-image: url(../assets/images/blog_img2.jpg);
  }

  h1 {
    font-size: 42px;
    font-weight: 700;
    color: $dark;
  }

  .breadcrumb {
    a,
    p {
      color: $secondary;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 500;
      margin: auto 0;
    }

    svg {
      margin-top: -3px;
    }
  }

  p,
  li {
    font-size: 20px;
    font-family: $font-secondary;
    font-weight: 500;
  }

  h2 {
    font-family: $font-primary;
    font-weight: 700;
    font-size: 28px;
    color: $primary;
  }

  .btn {
    padding: 12px 18px;
    font-size: 20px;
    width: fit-content;
    box-shadow: 0px 4px 40px -3px rgba($primary, 0.75) !important;

    &.btn-primary {
      background-color: $light;
      color: $primary;
    }

    &.btn-tertiary {
      background-color: transparent;
      color: $light;
      border-color: $light;
    }
  }

  .img-wrapper {
    width: 96%;
    height: 600px;
    margin: 35px;
    border-radius: 20px;
    border: 1px solid rgba($dark, 0.3);
    box-shadow: 0 30px 80px -20px rgba($dark, 0.25);
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .author-details {
    position: relative;
    display: flex;
    align-items: center;

    p {
      width: fit-content;
      padding-right: 15px;
      background-color: white !important;
      margin: 0;
      font-family: $font-secondary;
      font-size: 18px;
      z-index: 1;
      color: rgba($dark, 0.75);

      &.name {
        font-weight: 700;
      }

      &.date {
        font-weight: 600;
      }
    }

    &::after {
      position: absolute;
      right: 0;
      content: "";
      width: 100%;
      height: 2px;
      background-color: rgba($dark, 0.2);
    }
  }

  .content {
    p {
      line-height: 36px;

      h2 {
        margin: 25px 0;
      }

      a {
        text-decoration: none;
        cursor: pointer;
        color: rgba($primary, 0.75);
        border-bottom: 1px solid rgba($primary, 0.3);
        @include transition;

        &:hover {
          color: $primary;
          border-bottom: 1px solid $primary;
        }
      }

      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: contain;
        margin: 10px 0;
        border-radius: 10px;
      }

      @media screen and (max-width: $mobile_res) {
        font-size: 18px;
        line-height: 32px;

        li {
          font-size: 18px;
        }

        h2 {
          font-size: 22px;
        }

        img {
          height: 100%;
        }
      }
    }
  }

  @media screen and (max-width: $mobile-res) {
    h1 {
      font-size: 28px;
      max-width: 90%;
    }

    .img-wrapper {
      width: 95%;
      height: 260px;
      margin: 20px;
      border-radius: 10px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .btn {
      padding: 12px 18px;
      font-size: 18px;
    }
  }
}

#loginPage {
  .card {
    background-color: rgba($light, 0.25);
    width: 100%;
    max-width: 450px;
    height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0px 4px 40px -3px rgba($primary, 0.1);
    gap: 20px;

    .gsi-material-button {
      $transition-time: 0.218s;
      $border-color: #747775;
      $text-color: #1f1f1f;
      $background-color: white;
      $disabled-bg-color: rgba(#ffffff, 0.61);
      $disabled-border-color: rgba(#1f1f1f, 0.1);
      $hover-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
      $active-bg-color: #303030;
      $active-opacity: 12%;
      $hover-bg-color: #303030;
      $hover-opacity: 8%;

      background-color: $background-color;
      background-image: none;
      border: 1px solid $border-color;
      border-radius: 40px;
      box-sizing: border-box;
      color: $text-color;
      cursor: pointer;
      font-size: 16px;
      height: 50px;
      letter-spacing: 0.25px;
      outline: none;
      overflow: hidden;
      padding: 0 12px;
      position: relative;
      text-align: center;
      vertical-align: middle;
      white-space: nowrap;
      width: fit-content;
      max-width: 400px;
      min-width: min-content;

      &:disabled {
        cursor: default;
        background-color: $disabled-bg-color;
        border-color: $disabled-border-color;

        .gsi-material-button-contents {
          opacity: 38%;
        }

        .gsi-material-button-icon {
          opacity: 38%;
        }
      }

      .gsi-material-button-icon {
        height: 28px;
        margin-right: 12px;
        min-width: 20px;
        width: 25px;
        cursor: pointer;
      }

      .gsi-material-button-content-wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 100%;
        justify-content: space-between;
        position: relative;
        width: 100%;
        cursor: pointer;

        .gsi-material-button-contents {
          flex-grow: 1;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: top;
          cursor: pointer;
        }
      }
    }

    a {
      width: fit-content;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
      color: rgba($primary, 0.75);
      border-bottom: 1px solid rgba($primary, 0.3);
    }
  }
}

#settingsPage {
  margin-top: 11vh;

  a {
    text-decoration: none;
    cursor: pointer;
    color: rgba($primary, 0.75);
    border-bottom: 1px solid rgba($primary, 0.3);
    @include transition;

    &:hover {
      color: $primary;
      border-bottom: 1px solid $primary;
    }
  }

  .title-box {
    background-image: url(../assets/images/contact_img.jpg);
  }

  p {
    font-size: 20px;
    font-family: $font-secondary;
    line-height: 1.75;
    font-weight: 500;

    span {
      font-family: $font-secondary !important;
      font-weight: 700;
    }
  }

  h2 {
    font-family: $font-primary;
    font-weight: 700;
    font-size: 24px;
    color: $primary;
  }

  hr {
    border: 1px solid rgba($primary, 0.25);
    margin: 0;
  }

  .table {
    .form-control {
      border: none;
      border-radius: 0;
      padding: 0;
      box-shadow: none !important;
      resize: none !important;
      min-width: 300px;
      height: 100%;
    }

    textarea {
      padding-right: 5px !important;
    }

    th,
    td {
      padding: 6px 12px;
      vertical-align: middle;
    }

    ::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
      background-color: rgba($dark, 0.2);
    }
  }

  @media screen and (max-width: $mobile-res) {
    margin-top: 5vh;

    .container {
      padding: 0 4vw;
    }

    .title-box {
      h1 {
        top: 45%;
        bottom: 0;
      }
    }

    .text-box {
      p {
        font-size: 18px;
      }
    }

    // .table {
    //   min-width: 800px;
    //   overflow-x: scroll;
    // }
  }
}

.img-loading {
  position: relative;
  background-color: $light;
  height: 100%;
  width: auto;
  overflow: hidden;
  border: none;
  z-index: -1;

  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(transparent),
      color-stop(rgba(255, 255, 255, 0.75)),
      to(transparent)
    );
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.03),
      transparent
    );
    animation: loading 1.3s infinite;
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.loader {
  width: 100%;
  position: fixed;
  z-index: 999;

  img {
    width: 300px;
    height: 100%;
    animation: breathe 2s ease infinite;
  }

  @keyframes breathe {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
      opacity: 0.05;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.rte-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 8px !important;
  border: 1px solid rgba($secondary, 0.25);

  .RichTextEditor__root___2QXK- {
    border: none !important;
  }

  .rte-toolbar {
    width: inherit;
    border-bottom: 0 !important;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 2;

    @media (max-width: $mobile_res) {
      .ButtonGroup__root___3lEAn {
        .ButtonWrap__root___1EO_R {
          padding: 0 0 0 0 !important;
        }
      }
    }

    .InputPopover__root___3Hpj9 {
      width: fit-content;
      padding: 5px;

      .InputPopover__input___264Za {
        height: 35px;
        outline: none;
        cursor: text;
        color: black;
      }

      .InputPopover__checkOption___32S87 label span {
        color: black;
      }
    }

    .ButtonGroup__root___3lEAn {
      margin-right: 10px;

      .Dropdown__root___3ALmx {
        background-color: var(--light-accent);
        padding: 3px;

        select {
          border: none;
          padding-left: 6px;
          background: none;
          outline: none;

          option {
            color: black;
          }
        }
      }

      .ButtonWrap__root___1EO_R {
        border-radius: 3px;
        background-color: var(--light-accent);
        margin: 0;

        button {
          background: none;
          border: 1px solid rgba($dark, 0.25) !important;
          margin-right: 5px;
          border-radius: 5px;

          span {
            cursor: pointer;
            filter: var(--invert-rte-toolbar-color);
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;

            span {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  .rte-editor {
    margin-top: 40px;
    margin-left: -8px;
    height: 300px;
    border-radius: 10px !important;
    cursor: text;

    @media (max-width: $mobile_res) {
      margin-top: 70px;
      margin-left: -16px;
    }
  }
}

.ReactTags__selected {
  margin-top: 20px;
  display: inline-flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;

  .ReactTags__tag {
    background-color: rgba($primary, 0.1);
    width: fit-content;
    padding: 10px 4px 10px 10px;
    border-radius: 5px;
  }

  button {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    margin: 0 5px 0 10px;

    svg {
      fill: $secondary !important;
      width: 16px;
      height: 16px;
      padding-bottom: 3px;
    }
  }
}

.ReactTags__tagInputField {
  width: 100%;
  padding: 10px 12px !important;
  box-shadow: none !important;
  border: 1px solid rgba($secondary, 0.25) !important;
  cursor: text;
  border-radius: 8px !important;
  box-shadow: none !important;
  background-color: #fff !important;
  color: $dark !important;
  outline: none !important;
  cursor: text;

  &:focus,
  &:active,
  &:hover {
    border-color: $primary !important;
  }

  &::placeholder {
    color: rgba($dark, 0.5) !important;
  }
}

#main-tooltip {
  color: $light;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid rgba($primary, 0.25);
  color: $dark;
  padding: 6px 12px;
  z-index: 1;
}

.sx-verified-seal {
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 9;
  @include transition;

  img {
    width: 180px;
    opacity: 0.75;
    cursor: pointer;
    @include transition;
  }

  &:hover {
    transform: translateY(-4px);

    img {
      opacity: 1;
    }
  }
}

footer {
  padding: 30px 0;
  background-image: linear-gradient(
    to left,
    rgba($secondary, 0.2),
    rgba($primary, 0.2)
  );

  p {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($secondary, 0.5);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
